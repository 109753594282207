




















































































































































































































































































































































































import { Cliente, Porto } from "@/core/models/geral";
import { Embarcacao, Proposta, RegistroOperacional, RegistroOperacionalEnvioAprovacao } from "@/core/models/operacional";
import { PageBase } from "@/core/models/shared";
import { EnumTipoUsuario } from "@/core/models/shared/Enumerados";
import { ClienteService, FuncionarioService, PortoService } from "@/core/services/geral";
import { EmbarcacaoService, PropostaService, RegistroOperacionalService, SituacaoRegistroOperacionalService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertQuestion, AlertSimple, AlertSimpleErr, AlertSimpleRes } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaRegistroOperacional extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    dialogEnviarROEmail: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;
    valid = true;
    overlay: boolean = false;

    tipoUsuario = EnumTipoUsuario;

    excelDialog: boolean = false;
    excelOpcao: string = "padrao";
    excelOpcaoAtracacao: string = "atracacao";
    excelOpcaoTerno: string = "terno";
    excelOpcaoFainas: string = "faina";
    excelOpcaoEquipamentos:string = "equipamento";
    options: any = {
        itemsPerPage: 15
    };
    $refs!:{
        form: HTMLFormElement
    }
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: '#', value: 'numero', type:'number' },
        { text: '# Proposta', value: 'proposta.numero', type:'number'},
        { text: 'Cliente', value: 'proposta.cliente.nomeFantasia'},
        { text: 'Porto', value: 'porto.nome' },
        { text: 'Embarcacao', value: 'embarcacao.nome' },
        { text: 'Data Atracação', value:'atracacao.dataHoraEntrada', type:'date'},
        { text: 'Data Desatracação', value:'atracacao.dataHoraSaida', type:'date'},
        { text: 'Responsável', value: 'proposta.responsavel.nome' },
        { text: 'Atracação', value: 'hasAtracacao' },
        { text: 'Nº Nota Fiscal', value:'numeroNotaFiscal', type:'number'},
        { text: 'Pasta Documentos', value: 'linkDocumentos' },
        { text: 'Docs Cliente', value: 'linkDocumentosCliente' },
        { text: 'Situação', value: 'situacao.nome' },
        { text: 'Data Documento', value: 'dataDocumento'}
    ];
    filtro: any = {
        numero: null,
        dataDocumentoInicial: null,
        dataDocumentoFinal: null,
        atracacaoEntradaInicial : null,
        atracacaoEntradaFinal : null,
        atracacaoSaidaInicial : null,
        atracacaoSaidaFinal : null,
        propostaId: null,
        clienteId: null,
        osNumero: null,
        embarcacaoId: null,
        situacaoId: null,
        atracacao: null,
        responsavelId: null,
        portoId: null,
        localOperacaoId: null,
    };

    item = new RegistroOperacional();
    service = new RegistroOperacionalService();

    funcionarioService = new FuncionarioService();
    responsaveis: Cliente[] = [];
    isResponsavelLoading: boolean = false;
    onSearchResponsavel: any = null;

    clienteService = new ClienteService();
    clientes: Cliente[] = [];
    isClienteLoading: boolean = false;
    onSearchCliente: any = null;

    propostaService = new PropostaService();
    propostas: Proposta[] = [];
    isPropostaLoading: boolean = false;
    onSearchProposta: any = null;

    embarcacaoService = new EmbarcacaoService();
    embarcacoes: Embarcacao[] = [];
    isEmbarcacaoLoading: boolean = false;
    onSearchEmbarcacao: any = null;

    situacaoService = new SituacaoRegistroOperacionalService()
    situacoes: any[] = [];

    registroOperacaoEnvio: any = {};
    headerEmail: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Email', value: 'email'},
    ];
    emailEscrito: string = "";
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];
    emailRules: any[] = [ 
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail precisa ser válido'
    ]
    validEmail: boolean = true;

    atracacoes: any[] = [
        { value: null, nome: "TODOS" },
        { value: true, nome: "Sim" },
        { value: false, nome: "Não" }
    ];

    enviandoEmail: boolean = false;
    dialogAvaliacao: boolean = false;
    aprovacoes: RegistroOperacionalEnvioAprovacao[] = [];
    aprovacoesHeaders: any[] = [
        { text: 'Assunto', value: 'assunto', class: "primary ; white--text" },
        { text: 'Texto', value: 'texto', class: "primary ; white--text" },
        { text: 'Data/Hora', value: 'dataHora', class: "primary ; white--text" },
        // { text: 'Total', value: 'total', class: "primary ; white--text" },
        // { text: 'Situação', value: 'situacao', class: "primary ; white--text" },
        // { text: 'Aguardando', value: 'respondidas', class: "primary ; white--text" },
    ];
    aprovacoesItensHeaders: any[] = [
        { text: 'E-mail', value: 'email', class: "primary ; white--text" },
        { text: 'Justificativa', value: 'justificativa', class: "primary ; white--text" },
        { text: 'Data/Hora', value: 'dataHora', class: "primary ; white--text" },
        { text: 'Aprovação', value: 'aprovado', class: "primary ; white--text" },        
    ];

    detalharDialog: boolean = false;
    detalharLayout: string = '1';
    detalharId: number = 0;

    portos: any[] = [];
    localOperacoes: any[] = [];
    portoService: PortoService = new PortoService();

    situacaoRegistroOperacionalClass(item:any){
        if(item.situacaoId == 3)
            return 'roAprovada'
        else if(item.situacaoId == 4)
            return 'roReprovada'
        else if(item.situacaoId == 6)
            return 'roEnviada'
        else{
            return ''
        }
    }

    situacaoRegistroOperacionalItem(item:any){
        if(item.aprovado == null)
            return 'roEnviada'
        else if(item.aprovado)
            return 'roAprovada'
        else
            return 'roReprovada'
    }

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true){

        // if(isFiltro)
        //     this.options.page = 1;
        var include = "Embarcacao, Proposta.Cliente, Proposta.Responsavel, Situacao, Porto, Aprovacoes.Itens, Cabecos.Cabeco";

        if(this.app.perfilId == this.tipoUsuario.Cliente){
            this.filtro.clienteId = this.app.clienteId;
            this.headers.splice(10,1);
            include += ", Agendamento";
            this.headers.splice(10,0, { text: 'Docs Agendamento', value: 'agendamento.linkDocumentos' })
        }

        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.sheet = false;        

        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtro, include).then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    @Watch('onSearchResponsavel')
    SearchResponsavel (val: string) {

        if (this.isResponsavelLoading) return;
        if(this.filtro.responsavelId) return;
        if (!val) return;

        this.isResponsavelLoading = true
        this.funcionarioService.AutoComplete(val)
        .then(
            res => {
                this.responsaveis = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isResponsavelLoading = false));
    }

    @Watch('onSearchEmbarcacao')
    SearchEmbarcacao (val: string) {
        if (this.isEmbarcacaoLoading) return;
        if(this.filtro.embarcacaoId) return;
        if (!val) return;
        this.isEmbarcacaoLoading = true
        this.embarcacaoService.AutoComplete(val).then(
            res => {
                this.embarcacoes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isEmbarcacaoLoading = false));
    }

    @Watch('onSearchProposta')
    SearchProposta (val: string) {
        if (this.isPropostaLoading) return;
        if(this.filtro.propostaId) return;
        if (!val) return;
        this.isPropostaLoading = true
        this.propostaService.AutoComplete(val).then(
            res => {
                this.propostas = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isPropostaLoading = false));
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.isClienteLoading) return;
        if (this.filtro.clienteId) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val).then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        .finally(() => (this.isClienteLoading = false));
    }

    @Watch("filtro.portoId")
    WatchPorto(){
        if(this.filtro.portoId > 0){
            this.localOperacoes = [];
            this.portoService.ObterPorId(this.filtro.portoId, "LocaisOperacao").then(
                res => {
                    this.localOperacoes = res.data.locaisOperacao.filter(x => x.ativo == true);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }            
    }

    mounted() {
        this.situacaoService.ListarTudo().then(
            res=>{
                this.situacoes = res.data.items;
                this.situacoes.unshift(
                    {
                        id: null,
                        nome: 'TODOS'
                    }
                )
            },
            err => AlertSimpleErr("Aviso!", err)
        )
        this.portoService.ListarTudo().then(
            res => {
                this.portos = res.data.items;
                this.portos.unshift(
                    {
                        id: null,
                        nome: 'TODOS'
                    }
                )
            },
            err => AlertSimpleErr("Aviso!", err)
        )
    }

    AbrirDialogCadastro(item?: RegistroOperacional){
        if(item){
            this.overlay = true;
            this.service.ObterPorId(item.id, "Ternos.Terno, Fainas.Faina, Fainas.UnidadeMedida, Equipamentos.Equipamento, Equipamentos.Fornecedor, Equipamentos.PedidoCompras, Cabecos.Cabeco.Operador, Atracacao.LocalOperacao, Agendamento").then(
                res=>{
                    this.item = new RegistroOperacional(res.data);
                    this.service.ListarFuncionarios(item.id).then(
                        res => {
                            let funcionarios = res.data;
                            this.item.funcionarios = funcionarios;
                            this.dialogCadastro = true;
                        },
                        err => AlertSimpleErr("Aviso!", err)
                    )
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.overlay = false;
            })
        }
        else{
            this.item = new RegistroOperacional();
            this.item.empresaId = this.app.empresaId;
            this.item.situacaoId = 1;
            this.dialogCadastro = true;
        }
    }

    AbrirDialogEnviarRO(item: RegistroOperacional){        
        this.registroOperacaoEnvio = new RegistroOperacionalEnvioAprovacao();
        this.registroOperacaoEnvio.usuarioId = this.app.usuarioId;
        this.registroOperacaoEnvio.registroOperacionalId = item.id;
        if(item.proposta.cliente.email.length > 0){
            this.registroOperacaoEnvio.itens.unshift(
                {
                    email: item.proposta.cliente.email
                }
            )
        }
        this.dialogEnviarROEmail = true;
    }

    AdicionarEmailEnviar(){
        if(this.emailEscrito.length > 0){
            this.registroOperacaoEnvio.itens.push(
                {
                    email: this.emailEscrito
                }
            )
            this.emailEscrito = ""; 
        }               
    }

    EnviarEmail(){
        if (this.$refs.form.validate()) {
            if(this.registroOperacaoEnvio.itens.length == 0)
                AlertSimple("Aviso", "Você deve adicionar pelo menos um email para poder enviar!", "warning");
            else {
                this.enviandoEmail = true;
                this.overlay = true;
                this.service.EnviarROEmail(this.registroOperacaoEnvio).then(
                    res=>{
                        AlertSimpleRes("Aviso!", res);
                        this.dialogEnviarROEmail = false;
                        this.Atualizar();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                ).finally(() => {
                    this.overlay = false;
                    this.enviandoEmail = false;
                })
            }
        }        
    }

    ExcluirEmail(item: any){
        const index = this.registroOperacaoEnvio.itens.indexOf(item)
        this.registroOperacaoEnvio.itens.splice(index,1);
    }

    Aprovacao(item: RegistroOperacionalEnvioAprovacao[]){
        this.aprovacoes = item;
        this.dialogAvaliacao = true;
    }

    AbrirJanelaBM(id: number, rota: string){
        let routeLink = this.$router.resolve(
            { 
                name: rota, 
                params: { 
                    id: id.toString()
                } 
            }
        );
        window.open(routeLink.href, '_blank');
    }

    AbrirJanelaCliente(id: number, rota: string){
        let routeLink = this.$router.resolve(
            { 
                name: rota, 
                params: { 
                    id: id.toString(),
                },
                query:{
                    layout: '2'
                }
            }
        );
        window.open(routeLink.href, '_blank');
    }

    AbrirJanela(id?: number, ir: boolean = false) {
        if(id)
            this.detalharId = id;
        if(ir){
            let routeLink = this.$router.resolve(
                { 
                    name: 'detalharRegistroOperacional', 
                    params: { 
                        id: this.detalharId.toString(),
                    },
                    query:{
                        layout: this.detalharLayout                        
                    }
                }
            );
            window.open(routeLink.href, '_blank');
        }
        else{
            this.detalharDialog = true;
        }
    }

    Imprimir(){
        let routeLink = this.$router.resolve({ name: 'ralatorioRegistroOperacional',
            query:{
                numero: this.filtro.numero,
                propostaId: this.filtro.propostaId,
                clienteId: this.filtro.clienteId,
                osNumero: this.filtro.osNumero,
                embarcacaoId: this.filtro.embarcacaoId,
                situacaoId: this.filtro.situacaoId,
                atracacao: this.filtro.atracacao,
                responsavelId: this.filtro.responsavelId,
                portoId: this.filtro.portoId,
                localOperacaoId: this.filtro.localOperacaoId
            }
        });
        window.open(routeLink.href, '_blank');
    }

    GerarExcel(opcao: string){
        if(opcao == 'padrao'){
            this.ExportarExcel('tabela');
        }
        else if(opcao == 'atracacao'){
            this.service.ExcelAtracacoes(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId, this.filtro.portoId, this.filtro.localOperacaoId, this.filtro.atracacaoEntradaInicial, this.filtro.atracacaoEntradaFinal, this.filtro.atracacaoSaidaInicial, this.filtro.atracacaoSaidaFinal).then(
                res => {
                    this.JsonToCSV(res.data,"Atracação",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else if (opcao == 'terno'){
            this.service.ExcelTernos(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId, this.filtro.portoId, this.filtro.localOperacaoId, this.filtro.atracacaoEntradaInicial, this.filtro.atracacaoEntradaFinal, this.filtro.atracacaoSaidaInicial, this.filtro.atracacaoSaidaFinal).then(
                res => {
                    this.JsonToCSV(res.data,"Terno",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else if (opcao == 'faina'){
            this.service.ExcelFainas(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId, this.filtro.portoId, this.filtro.localOperacaoId, this.filtro.atracacaoEntradaInicial, this.filtro.atracacaoEntradaFinal, this.filtro.atracacaoSaidaInicial, this.filtro.atracacaoSaidaFinal).then(
                res => {
                    this.JsonToCSV(res.data,"Faina",true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else {
            this.service.ExcelEquipamento(this.filtro.numero, this.filtro.propostaId, this.filtro.situacaoId, this.filtro.atracacao, this.filtro.clienteId, this.filtro.responsavelId, this.filtro.embarcacaoId, this.filtro.portoId, this.filtro.localOperacaoId, this.filtro.atracacaoEntradaInicial, this.filtro.atracacaoEntradaFinal, this.filtro.atracacaoSaidaInicial, this.filtro.atracacaoSaidaFinal).then(
                res => {
                    this.JsonToCSV(res.data,'Equipamento',true)
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
    }

    ConcluirManualmente(item: RegistroOperacional){
        const context = this;
        const concluir = function () {
            return new Promise( async function (resolve, reject){
                context.service.ConcluirManualmente(item.id).then(
                    res => {
                        AlertSimpleRes("Aviso!", res);
                        context.Atualizar();
                    },
                    err => AlertSimpleErr("Aviso!", err)
                )
            });
        }
        AlertQuestion("Atenção!", "Tem certeza que deseja concluir o registro atual?", concluir);
    }

    Excluir(item: RegistroOperacional){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }

}
